@import "../global.scss";

.container {
    box-shadow: 0px 6px 13px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 10px;
    color: $blue;
    border: 1px solid $gold;
    background-color: $gold;

    @include scr-2k {
        font-size: 20px;
        line-height: 22px;
    }

    @include scr-4k {
        font-size: 22px;
        line-height: 25px;
    }

    @include phone {
        font-size: 14px;
    }

    &:hover {
        transition: all 100ms ease-in-out;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.12);
        border: 1px solid $gold;
        background: transparent;
        color: $gold;
    }
}