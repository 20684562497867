// styles/AdminAccess.module.scss

@import '../global.scss';

// General styles
$primary-color: #dbb42d;
$gold: #ffd700;

.container1 {
	max-height: 65vh;
	border: 1px solid $gold;
	border-radius: 20px;
	overflow-y: auto;
	margin: 10px 0px;

	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-track {
		background: #00308f;
		border-radius: 20px;
		height: 90%;
		margin-top: 7px;
		margin-bottom: 7px;
	}

	&::-webkit-scrollbar-thumb {
		background: #f1f1f1;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: #f1f1f1;
	}

	.container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: auto;
		.upper {
			overflow-y: auto;
			padding: 0px 60px;
			border: 1px solid $primary-color;
			border-radius: 10px;
			justify-content: 'center';
			height: 'auto';

			&::-webkit-scrollbar {
				width: 5px;
				height: 5px;
				border-radius: 10px;
			}

			&::-webkit-scrollbar-track {
				background: #00308f;
				border-radius: 20px;
				height: 90%;
				margin-top: 7px;
				margin-bottom: 7px;
			}

			&::-webkit-scrollbar-thumb {
				background: #f1f1f1;
				border-radius: 10px;
			}

			&::-webkit-scrollbar-thumb:hover {
				background: #f1f1f1;
			}
		}
		.table1 {
			justify-content: center;
			text-align: center;
			padding-top: 15px;
			table {
				border-collapse: collapse;
				width: 100%;
				margin-bottom: 20px;
				thead {
					tr {
						th {
							padding-bottom: 10px;
						}
					}
				}
				caption {
					font-weight: bold;
					padding-top: 30px;
				}

				tbody {
					padding-top: 20px;
					gap: 10px;
					row-gap: 10px;
					tr {
						padding-top: 20px;
						padding-bottom: 10px;
						border-top: 2px solid $white !important;
						text-align: center !important;
						th {
							padding-left: 20px;
							padding-right: 20px;
						}
						td {
							padding-left: 20px;
							padding-right: 10px;

							input {
								width: 70px;
								height: 30px;
								border: 1px solid #ccc;
								border-radius: 5px;
								padding: 5px;
							}

							button {
								margin-top: 10px;
							}
						}
					}
				}
			}
		}

		.lower {
			display: flex;
			flex-direction: column;
			margin-top: 20px;
			padding: 20px 40px;
			border: 1px solid $primary-color;
			border-radius: 10px;
			filter: drop-shadow(0px 5px 9px rgba(165, 159, 159, 0.22));
			height: auto;

			.lower2 {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin-top: 20px;
				height: auto;
			}

			input {
				width: 150px;
				height: 40px;
				border: 1px solid #ccc;
				border-radius: 5px;
				padding: 5px;
			}
			.dropdown2 {
				padding: 10px 0;
				position: absolute;
				background-color: white;
				color: black;
				text-align: center;
				font-size: medium;
				box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
				border-radius: 12px;
				overflow: hidden;
				min-width: 180px;

				div {
					padding: 10px 20px;
					font-weight: 300;
					font-size: 20px;
					color: #898989;
					cursor: pointer;

					@include scr-2k {
						font-size: 25px;
					}

					@include scr-4k {
						font-size: 32px;
					}

					&:hover {
						background-color: $gold;
						color: $blue;
						font-weight: 600;
					}
				}

				hr {
					margin: 0 20px;
					border: 1px solid #e7e7e7;
				}
			}
		}
	}
}
