@import "../global.scss";

.container {
    border: 1px solid $brightGold;
    border-radius: 10px;
    height: 65vh;
    overflow-y: auto;

    .usersTitleSection {
        display: flex;
        padding: 0 1em 0 2em;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;


        h3 {
            font-size: 24px;
            color: $gold;
        }

        .ExportBtn {
            @include Btn;
            margin: 0px 25px;
            width: 200px;
            height: 40px;
        }

        .userSearchForm {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            grid-gap: 15px;

            .searchInputSection {
                display: flex;
                flex-direction: row;
                align-items: center;
                background-color: $grey500;
                border-radius: 10px;
                margin: 3px 0;

                input {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    gap: 10px;
                    background: $grey500;
                    border: 1px solid $grey500;
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
                    border-radius: 10px;
                    padding: 5px 20px;

                    &::placeholder {
                        color: $gray;
                        opacity: 1;
                    }

                    .icon,
                    .tool {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 20px;
                        cursor: pointer;

                        svg,
                        img {
                            height: 100%;
                        }
                    }

                    input {
                        padding: 10px 0;
                        flex: 1;
                    }

                    min-height: 52.5px;

                    &:focus {
                        outline: none;
                    }
                }

                select {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    gap: 10px;
                    border: 4px solid $gold;
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
                    border-radius: 0 10px 10px 0;
                    padding: 10px 20px;
                    background: $gold;
                    min-height: 52.5px;
                    margin: 0;

                    &:focus {
                        outline: none;
                    }
                }

                .dropdownBtn {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    gap: 10px;
                    border: 4px solid $gold;
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
                    border-radius: 0 10px 10px 0;
                    padding: 10px 20px;
                    background: $gold;
                    min-height: 52.5px;
                    margin: 0;

                    &:focus {
                        outline: none;
                    }
                }
            }


        }
    }

    table {
        td.status {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 21px;
            display: flex;
            align-items: center;
            gap: 10px;
            margin: auto;
            justify-content: center;
            width: 200px;

            &.approved {
                color: #1cb46b;
                text-align: center;
                margin: auto;
            }

            &.pending {
                color: #7364d9;
                text-align: center;
                margin: auto;
            }

            &.notApplied {
                color: #db2626;
                text-align: center;
                margin: auto;
            }

            &.rejected {
                color: #db2626;
                text-align: center;
                margin: auto;
            }
        }

        td {
            .setFeesBtn {
                background-color: rgb(232, 203, 105);
                border: 1px solid rgb(232, 203, 105);
                color: rgb(9, 26, 73);
                font-weight: 600;
                padding: 2px;
                min-width: 125px !important;
                height: 33px;
                font-size: 16px;
            }
        }
    }

    .foot {
        padding: 20px 0;
        width: 100%;
        display: grid;
        place-items: center;

        p {
            color: $gold;
        }
    }
}