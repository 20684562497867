@import '../global.scss';

// .container {
//     max-width: 1400px;
//     height: 60vh;
//     overflow-y: scroll;

//     .dropdownContainer {
//         display: flex;
//         column-gap: 5px;
//         margin-bottom: 15px;

//         .statusDropdown {
//             background: transparent;
//             color: white;
//             border: 1px solid $gold;
//             border-radius: 10px;
//             height: 50px;
//             font-size: 16px;
//             font-weight: bold;
//             padding: 5px 10px;
//             width: max-content;
//             display: flex;
//             flex-direction: row;
//             align-items: center;
//             justify-content: space-between;
//             grid-gap: 20px;

//             @include scr-2k {
//                 font-size: 22px;
//                 height: 60px;
//                 padding: 0 15px;
//             }

//             @include scr-4k {
//                 font-size: 35px;
//                 height: 80px;
//                 padding: 0 20px;
//             }

//             option {
//                 background: white;
//                 color: $blue;
//                 padding: 5px;
//             }

//             img {
//                 width: 20px;
//                 height: 20px;
//             }
//         }
//     }

//     &::-webkit-scrollbar {
//         display: none;
//     }

//     .rows {
//         display: flex;
//         justify-content: space-between;

//         &>div {
//             background: rgb(38, 50, 56);
//             width: 300px;
//             padding: 10px 0px;
//             color: white;
//             margin-bottom: 15px;
//             border-radius: 5px;
//         }

//         &>div:nth-child(2) {
//             width: 200px;
//             height: 40px;
//         }

//         &>div:last-child {
//             width: 500px;
//         }

//         .datePicker {
//             height: 40px;
//             padding-left: 15px;

//             button {
//                 background: transparent;
//                 outline: none;
//                 border: none;
//                 color: white;
//             }
//         }

//         .headerText {
//             padding-left: 15px;
//         }

//         .imageLogo {
//             padding: 1px;
//             height: 75px;

//             img {
//                 width: 298px;
//                 height: 73px;
//             }
//         }

//         .totalTraded {
//             background: transparent;

//             &>div {
//                 background: rgb(38, 50, 56);
//                 padding: 10px;
//                 padding-left: 25px;
//                 color: white;
//                 border-radius: 5px;

//                 &>div:last-child {
//                     font-size: 24px;
//                 }
//             }
//         }

//         .middleRow {
//             display: flex;
//             flex-direction: column;
//             justify-content: space-between;
//             background: transparent;

//             &>div {
//                 margin-bottom: 20px;
//                 background: rgb(38, 50, 56);
//                 border-radius: 5px;
//                 padding: 10px 0px;
//                 padding-left: 20px;
//                 color: white;

//                 &>div:last-child {
//                     font-size: 24px;
//                 }
//             }
//         }

//         .noBackground {
//             background: transparent;

//             .mostTradedChart {
//                 height: 340px;
//                 background: rgb(38, 50, 56);
//                 border-radius: 5px;
//                 margin-top: 5px;
//                 display: flex;
//                 flex-direction: column;

//                 &>div:first-child {
//                     background: rgb(204, 204, 204);
//                     text-align: center;
//                     color: black;
//                 }

//                 .buySellContainer {
//                     display: flex;
//                     width: 100%;
//                     column-gap: 2px;
//                     height: 300px;

//                     &>div {
//                         width: 100%;
//                     }

//                     .header {
//                         background: rgb(204, 204, 204);
//                         text-align: center;
//                         color: black;
//                         margin: 2px 0px;
//                     }
//                 }
//             }
//         }

//         .headerContainer {
//             background: transparent;

//             &>div {
//                 background: rgb(38, 50, 56);
//                 border-radius: 5px;
//                 padding: 10px 0px;
//                 padding-left: 15px;
//                 color: white;
//             }

//             .fiatCurrency {
//                 display: flex;
//                 justify-content: space-between;
//                 padding-right: 10px;

//                 &>div:first-child {
//                     display: flex;
//                     column-gap: 5px;
//                     margin-bottom: 5px;
//                 }
//             }

//             .search {
//                 display: flex;
//                 column-gap: 5px;
//                 border-bottom: 1px solid black;
//                 padding-right: 10px;
//                 padding: 10px 0px;

//                 input[type='text'] {
//                     background: transparent;
//                     border: none;
//                     color: white;

//                     &:focus {
//                         outline: none;
//                     }
//                 }
//             }

//             .fiatCurrenciesContainer {
//                 display: flex;
//                 flex-direction: column;
//                 height: 80px;
//                 overflow-y: auto;

//                 .fiatCurrencies {
//                     display: flex;
//                     justify-content: space-between;
//                     margin-bottom: 10px;

//                     &>div:first-child {
//                         display: flex;
//                         justify-content: space-between;
//                         width: 100%;
//                         column-gap: 5px;
//                         margin-top: 5px;
//                     }

//                     .currency {
//                         display: flex;
//                         column-gap: 5px;
//                     }
//                 }
//             }
//         }

//         .table {
//             height: 490px;
//             position: relative;

//             table {
//                 width: 100%;

//                 thead {
//                     margin: 10px 0px;
//                     border-bottom: 2px solid red;

//                     tr {
//                         border-bottom: 2px solid white;
//                         height: 50px;

//                         th {
//                             color: white;
//                             text-align: center;
//                             @include Fonts(20px, 20px, bold);
//                             border-bottom: 2px soldi $white;

//                             @include scr-2k {
//                                 font-size: 25px !important;
//                                 line-height: 25px;
//                                 padding: 25px 20px;
//                             }

//                             @include scr-4k {
//                                 font-size: 40px !important;
//                                 line-height: 32px;
//                                 padding: 35px 20px;
//                             }
//                         }
//                     }
//                 }

//                 tbody {

//                     tr {
//                         text-align: center;
//                         border-bottom: 1px solid white;

//                         td {
//                             color: white;
//                             @include Fonts(15px, 35px, 500);

//                             @include scr-2k {
//                                 font-size: 20px !important;
//                             }

//                             @include scr-4k {
//                                 font-size: 33px !important;
//                             }

//                         }
//                     }
//                 }
//             }
//         }

//         .pageNumber {
//             position: absolute;
//             bottom: 10px;
//             right: 15px;
//         }
//     }
// }

// .doughnutChartContainer {
//     height: 300px;
//     background: rgb(38, 50, 56);
//     border-radius: 5px;
//     display: flex;

//     &>div {
//         margin-top: -100px;
//         margin-left: -20px;

//     }

//     &>div:last-child {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         font-size: 12px !important;

//         p {
//             display: flex;
//             align-items: center;
//             column-gap: 5px;

//             .deposit {
//                 height: 10px;
//                 width: 10px;
//                 background: #00b7cb;
//                 border-radius: 50%;
//                 margin-top: 10px;
//             }

//             .withdrawal {
//                 height: 10px;
//                 width: 10px;
//                 background: #0070f0;
//                 border-radius: 50%;
//                 margin-top: 10px;
//             }
//         }
//     }
// }

// .pieChartContainer {
//     height: 300px;
//     background: rgb(38, 50, 56);
//     border-radius: 5px;
//     display: flex;

//     &>div:first-child {
//         margin-top: -30px;
//         margin-left: -40px;
//         width: 400px;
//     }

//     &>div:last-child {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         font-size: 16px !important;

//         p {
//             display: flex;
//             align-items: center;
//             column-gap: 5px;

//             .individual {
//                 height: 10px;
//                 width: 10px;
//                 background: #00b7cb;
//                 border-radius: 50%;
//                 margin-top: 10px;
//             }

//             .institution {
//                 height: 10px;
//                 width: 10px;
//                 background: #0070f0;
//                 border-radius: 50%;
//                 margin-top: 10px;
//             }
//         }
//     }
// }

.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: auto;
	// overflow-y: 'scroll';
}
