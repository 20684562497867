@import '../global.scss';

.container {
	// display: flex;
	flex-direction: column;
	border: 1px solid $gold;
	border-radius: 20px;
	justify-content: center;
	padding: 16px 10px;
	margin: auto;
	height: 65vh;
	margin: 10px 0px;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 5px;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-track {
		background: #00308f;
		border-radius: 20px;
		height: 90%;
		margin-top: 7px;
		margin-bottom: 7px;
	}

	&::-webkit-scrollbar-thumb {
		background: #f1f1f1;
		border-radius: 10px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #f1f1f1;
	}

	.item {
		display: flex;
		align-items: center;
		cursor: pointer;
		padding: 14px;
		gap: 20px;
		z-index: 999;

		@include scr-2k {
			gap: 25px;
			padding: 35px 25px;
		}

		@include scr-4k {
			gap: 30px;
			padding: 45px 35px;
		}

		p {
			font-weight: 500;
			font-size: 22px;
			line-height: 31px;
			color: white;

			@include scr-2k {
				font-size: 27px;
			}

			@include scr-4k {
				font-size: 45px;
			}

			&:hover {
				text-decoration: none !important;
			}
		}

		svg,
		svg * {
			// fill: white;
			// stroke: white;

			@include scr-2k {
				width: 32px;
				height: 32px;
			}

			@include scr-4k {
				width: 55px;
				height: 55px;
			}
		}

		&.active {
			p {
				color: $brightGold;
			}

			svg,
			svg * {
				stroke: $brightGold;
			}
		}
	}
}
