@import '../global.scss';

.container {
	border: 1px solid $gold;
	border-radius: 20px;
	height: 65vh;
	overflow-y: scroll !important;

	&::-webkit-scrollbar {
		width: 0px;
		border-radius: 20px;
	}

	.providers {
		margin: 7px 0px;
		overflow-y: auto;
		height: 47.5vh;

		table {
			width: 100%;
			margin: 7px 0px;
			border-collapse: collapse;

			thead {
				margin: 10px 0px;
				border-bottom: 2px solid red;

				tr {
					border-bottom: 2px solid $white;
					border-top: 2px solid $white;
					height: 50px;

					th {
						color: $white;
						@include Fonts(20px, 20px, bold);
						width: 80px;
						border-bottom: 2px solid $white;
						// border-right: 1px solid $white;
						overflow-wrap: break-word;
						word-wrap: break-word;
						word-break: break-word;
						@include scr-2k {
							font-size: 25px !important;
							line-height: 25px;
							padding: 25px 20px;
						}

						@include scr-4k {
							font-size: 40px !important;
							line-height: 32px;
							padding: 35px 20px;
						}
					}
				}
			}

			tbody {
				tr {
					text-align: center;
					border-bottom: 1px solid $white;
					height: 50px;
					input {
						height: 40px;
						border: 1px solid #ccc;
						border-radius: 5px;
						padding: 5px;
						width: 90px;
						margin-bottom: 3px;
						margin-top: 3px;
					}
					th {
						padding: 0 10px;
					}

					td {
						color: white;
						padding: 0 10px;
						@include Fonts(15px, 35px, 500);
					}
					button {
						box-shadow: 0px 6px 13px rgba(0, 0, 0, 0.12);
						border-radius: 20px;
						font-weight: 700;
						font-size: 19px;
						height: 40px;
						width: 80px;
						color: $blue;
						border: 1px solid $gold;
						background-color: $gold;
					}
					.cancelbtn {
						box-shadow: 0px 6px 13px rgba(0, 0, 0, 0.12);
						border-radius: 20px;
						font-weight: 700;
						font-size: 25px;
						height: 40px;
						width: 40px;
						color: $blue;
						border: 1px solid $gold;
						background-color: $gold;
					}
				}
			}
		}
	}
	&::-webkit-scrollbar {
		width: 5px;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-track {
		background: #00308f;
		border-radius: 20px;
		height: 90%;
		margin-top: 7px;
		margin-bottom: 7px;
	}

	&::-webkit-scrollbar-thumb {
		background: #f1f1f1;
		border-radius: 10px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #f1f1f1;
	}
}

.ProcessBTN {
	@include Btn;
	width: 144px;
	font-size: 15px !important;
	height: 36px;
	margin: auto 0px;
}
