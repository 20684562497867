@import "../global.scss";

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5%;
    color: white;
    box-shadow: 0px 4px 4px rgba(57, 57, 57, 0.18);
    z-index: 5;
    background: $blue !important;
    width: 100%;
    margin-right: 10px;
    height: 11vh;

    .left {
        cursor: pointer;
        width: 290px;
        height: 61px;
        object-fit: cover;

        @include scr-2k {
            width: 350px;
            height: 80px;
        }

        @include scr-4k {
            width: 480px;
            height: 130px;
        }

    }

    .navigation {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .icons {
            margin: 0px 18px;

            .notificationIcon {
                position: relative;
            }

            .newNotifications {
                position: absolute;
                top: 10px;
                left: 250px;
                background: #ff4e4e;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                color: white;
                font-size: 12px;
                font-weight: 800;
                text-align: center;
            }

            @include scr-2k {
                margin: 0px 30px;
            }

            @include scr-4k {
                margin: 0px 45px;
            }

            .headerIcon {
                width: 30px;
                height: 30px;

                @include scr-2k {
                    width: 42px;
                    height: 42px;
                }

                @include scr-4k {
                    width: 60px;
                    height: 60px;
                }
            }

        }
    }

    .help {
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        line-height: 24px;
    }

    .adminType {
        text-align: right;
        width: 50%;
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        line-height: 24px;
        color: $gold;
        margin-right: 1rem;
    }

    .right {
        display: flex;
        align-items: center;
        position: relative;
        gap: 20px;

        .needHelpText {
            font-size: 19px;
            font-weight: 500;

            @include scr-2k {
                font-size: 25px;
            }

            @include scr-4k {
                font-size: 32px;
            }
        }

        .depositebtn {
            width: 150px;
            height: 42px;
            @include Btn;

            &:hover {
                background-color: $brightGold;
                color: $blue;
            }
        }

        .details {
            height: 60px;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 10px;
            margin-right: 25px;

            .dp {
                height: 30px;
                width: 30px;
                border-radius: 50%;
                background-color: #898989;
            }

            .initials {
                background: white;
                border-radius: 50%;
                padding: 8px;
                color: $blue;
                font-weight: 600;
                font-size: 16px;
                line-height: 21px;

                @include scr-2k {
                    font-size: 25px;
                    line-height: 30px;
                }

                @include scr-4k {
                    font-size: 32px;
                    line-height: 35px;
                }

            }

            p {
                color: white;
                font-size: 21px;
                font-weight: 500;

                @include scr-2k {
                    font-size: 25px;
                }

                @include scr-4k {
                    font-size: 32px;
                }
            }

            svg,
            svg * {
                height: 10px;
                fill: white;
            }
        }
    }
}

.dropdown {
    padding: 10px 0;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    overflow: hidden;
    min-width: 300px;
    margin-right: 20px;

    p {
        padding: 15px 20px;
        font-weight: 500;
        font-size: 15px;
        color: #898989;
        cursor: pointer;

        @include scr-2k {
            font-size: 25px;
        }

        @include scr-4k {
            font-size: 32px;
        }

        &:hover {
            background-color: $gold;
            color: $blue;
            font-weight: 600;
        }
    }

    hr {
        margin: 0 20px;
        border: 1px solid #e7e7e7;
    }
}