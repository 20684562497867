@import "../global.scss";

.flex {
    display: flex;
    align-items: flex-end;
    gap: 20px;

    &>* {
        flex: 1;
        width: 50%;
    }
}

.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    font-family: $fontfam2;

    label {
        margin: 3px 0px;
        color: $NewCol;
        font-family: $fontfam2;
        font-weight: 600;
        font-size: 16px;

        @include scr-2k {
            font-size: 20px;
        }

        @include scr-2k {
            font-size: 22px;
        }

        @include phone {
            font-size: 14px;
        }
    }

    .inputContainer {
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 10px;
        background: $grey500;
        border: 1px solid $grey500;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        padding: 5px 20px;
        margin: 3px 0px;

        @include phone {
            padding: 3px 13px;
        }

        &::placeholder {
            color: $grey500;
        }

        .icon,
        .tool {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            cursor: pointer;

            @include phone {
                height: 17px;
            }

            svg,
            img {
                height: 100%;
            }
        }

        input {
            padding: 10px 0;
            flex: 1;

            @include phone {
                padding: 7px 0px;
            }
        }

        min-height: 47px;

        button {
            @include Btn;
            width: 120px;
            height: 38px;
            font-size: 15px;
        }
    }

    .inputContainer2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        background: $grey500;
        border: 1px solid $grey500;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        padding: 5px 20px;

        @include phone {
            padding: 3px 18px;
        }

        .icon,
        .tool {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            cursor: pointer;

            @include phone {
                height: 17px !important;
            }

            svg,
            img {
                height: 100%;

                @include phone {
                    height: 85% !important;
                }
            }
        }

        input {
            padding: 10px 0;
            flex: 1;

            @include phone {
                padding: 7px 0px;
            }
        }

        min-height: 47px;
        min-width: 20rem;
    }

    &.file {
        .inputContainer {
            justify-content: unset;
            position: relative;
            cursor: pointer !important;

            p {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 20px;
                display: flex;
                align-items: center;
                color: #a8a8a8;

                @include scr-2k {
                    font-size: 15px;
                    line-height: 22px;
                }

                @include scr-4k {
                    font-size: 17px;
                    line-height: 26px;
                }
            }

            input {
                cursor: pointer !important;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 10;
            }

            &::after {
                content: "Browse";
                color: #6a6d72;
                position: absolute;
                top: 0;
                right: 5px;
                z-index: 5;
                padding: 8px 20px;
                top: 50%;
                transform: translate(0, -50%);
                border-radius: 10px;
                pointer-events: none;
                background: #d0d4d9;
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
            }
        }
    }

    &.checkbox {
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-end;

        .inputContainer {
            background-color: transparent;
            border: none;
            box-shadow: none;
            width: 50%;
            padding: 0;
            margin: 0;
        }
    }

    .error {
        color: $error;
        height: 20px;
        font-size: 13px;

        @include scr-2k {
            font-size: 16px;
        }

        @include scr-4k {
            font-size: 18px;
        }

    }
}

.container2 {
    @extend .container;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


@media (min-width:2559px) {

    .container {

        label {
            font-size: 27px;
            margin: 10px;
        }


        .inputContainer {

            .icon {
                height: 21px;

            }

            input {
                padding: 10px 0;
                flex: 1;
                font-size: 23px;
            }

        }

    }

}

@media (min-width:3840px) {

    .container {

        label {
            font-size: 32px;
            margin: 15px;
        }

        .inputContainer {

            .icon {
                height: 23px !important;

            }

            input {
                padding: 10px 0;
                flex: 1;
                font-size: 24px;
            }

        }

    }

}

.OTpinput {
    background: #F1F1F1 !important;
    border: #F1F1F1 !important;

    &:focus {
        border: 0px !important;
    }
}