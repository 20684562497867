@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
// COLORS

$secondary: #373434;
$light1: #eaf3fe;
$light2: #f7f7ff;
$white: #ffffff;
$gray: #989494;
$silver: #e9e9e9;
$success: #14bc24;
$info: #065cdd;
$error: #da1919;
$gold: #E8CB69;
$brightGold: #D5B54A;
$blue: #001B4D;
$lightBlue :#102d7c;
$green: #5F9816;
$red: #981616;
$black: #111111;
$lightgray: #424242;
$gray: #6C6C6C;
$gray200:#6C6C6C;
$gray300: #D9D9D9;
$gray400: #D1D1D1;
$grey500:#F1F1F1;
$fontfam:'Roboto',
'Rubik',
'Montserrat'
sans-serif;
$fontfam2:'Montserrat',
sans-serif;
$NewCol: #6B6C6F;
$NewGold:#DBB42C;
$statusApproved: #1CB46B;
$statusPending: #D68B00;
$statusRejected: #DB2626;
$dropdownBorderColor: #DBB42D;
$dropdownTextColor: #DBB42D;
$dropdownHover: #173486;


// DEVICES
@mixin phone-sm {
  @media (max-width: 500px) {
    @content;
  }
}

@mixin phone {
  @media (max-width: 768px) {
    @content;
  }
}


@mixin tablet {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin sm-laptop {
  @media (max-width: 1360px)and (min-width:800px) {
    @content;
  }
}

@mixin mid-scr {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin xl-scr {
  @media screen and (min-device-width: 1800px) and (max-device-width: 2000px) {
    @content;
  }
}

@mixin scr-2k {
  @media (min-width:2559px) {
    @content;
  }
}

@mixin scr-4k {
  @media (min-width:3840px) {
    @content;
  }
}

@mixin laptop-L {
  @media(min-width:1439) {
    @content;
  }
}

@function Color($theme) {
  @return map-get((primary: $blue,
      secondary: $secondary,
      success: $success,
      info: $info,
      error: $error,
    ),
    $theme);
}

.loader {
  border: 4px solid transparent;
  border-top: 4px solid $blue;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@mixin Fonts($size, $line, $weight) {
  font-weight: $weight;
  font-size: $size;
  line-height: $line;
}

@mixin Btn {
  background: $brightGold;
  color: $blue;
  border: 1px solid $gold;
  border-radius: 20px;
  @include Fonts(18px, 21px, 600);
  // margin: 40px 0px;

  &:hover {
    color: $gold;
    transition: all 100ms ease-in-out;
    background: transparent;
  }
}

@mixin Input {
  background-color: $grey500 !important;
  border: 1px solid $grey500;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 5px 20px;
  margin: 3px 0px;
}

// ? STATUS TEXT COLORS
.textStatusApproved {
  color: $statusApproved !important;
  font-weight: bold !important;
}

.textStatusPending {
  color: $statusPending !important;
  font-weight: bold !important;
}

.textStatusRejected {
  color: $statusRejected !important;
  font-weight: bold !important;
}

// ? PLACEHOLDING LOADERS
.loaderSection {
  width: 100%;
  text-align: center;
}

// ? dropdown

.drop-down {
  background-color: $blue !important;
  border: 1px solid $dropdownBorderColor !important;
  border-radius: 0 !important;
  color: $dropdownTextColor !important;

  .szh-menu__item {
    padding: 10px 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    grid-gap: 45px;
  }

  .szh-menu__item:hover {
    background-color: $dropdownHover !important;
  }

  .dropdown-item-selected {
    background-color: $dropdownHover !important;
    border-top: 1px solid $dropdownBorderColor !important;
    border-bottom: 1px solid $dropdownBorderColor !important;
    color: $white !important;
  }

}

.szh-menu {
  z-index: 1000000 !important;
}

.szh-menu__item--hover {
  background-color: $dropdownHover !important;
}

.errorText {
  color: $error;
  font-weight: bold;
}