@import "../global.scss";

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10%;
    background: $blue !important;
    color: white;
    box-shadow: 0px 4px 4px rgba(57, 57, 57, 0.18);
    z-index: 5;
    background: $blue;
    width: 100%;
    margin-right: 10px;
    height: 11vh;

    .left {
        cursor: pointer;
        width: 260px;
        height: 50px;
        object-fit: contain;

        @include scr-2k {
            width: 300px;
            height: 69px;
        }

        @include scr-4k {
            width: 320px;
            height: 70px;
        }
    }

    .navigation {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin: 0px 15px;

        .icons {
            margin: 0px 17px;

            .notificationIcon {
                position: relative;
            }

            .newNotifications {
                position: absolute;
                top: 10px;
                left: 250px;
                background: #ff4e4e;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                color: white;
                font-size: 12px;
                font-weight: 800;
                text-align: center;
            }
        }

    }

    .help {
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        line-height: 24px;
    }

    .right {
        display: flex;
        align-items: center;
        position: relative;
        gap: 15px;

        .depositebtn {
            width: 135px;
            height: 42px;
            @include Btn;

            @include scr-2k {
                width: 220px;
                height: 60px;
                font-size: 26px;
            }

            @include scr-4k {
                width: 240px;
                height: 65px;
                font-size: 27px;
            }

            &:hover {
                background-color: $brightGold;
                color: $blue;
            }
        }

        .details {
            height: 60px;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 10px;
            max-width: 300px;
            margin-right: 15px;

            .dp {
                height: 30px;
                width: 30px;
                border-radius: 50%;
                background-color: #898989;
            }

            .initials {
                background: white;
                border-radius: 50%;
                padding: 8px;
                color: $blue;
                font-weight: 600;
                font-size: 15px;
                line-height: 21px;

                @include scr-2k {
                    font-size: 18px;
                    padding: 10px;
                }

                @include scr-4k {
                    font-size: 19px;
                    padding: 12px;
                }
            }

            p {
                color: white;
                font-size: 16px;
                font-weight: 500;

                @include scr-2k {
                    font-size: 20px;
                }

                @include scr-4k {
                    font-size: 22px;
                }
            }

            svg,
            svg * {
                height: 10px;
                fill: white;
            }
        }
    }
}

.dropdown {
    padding: 10px 0;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    overflow: hidden;
    min-width: 300px;
    margin-right: 20px;

    p {
        padding: 15px 20px;
        font-weight: 500;
        font-size: 15px;
        color: #898989;
        cursor: pointer;

        @include scr-2k {
            font-size: 25px;
        }

        @include scr-4k {
            font-size: 32px;
        }

        &:hover {
            background-color: $gold;
            color: $blue;
            font-weight: 600;
        }
    }

    hr {
        margin: 0 20px;
        border: 1px solid #e7e7e7;
    }
}

// ? WALKTHROUGH SCREEN STYLINGS
.walkthroughBackdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(0, 0, 0, .8);

    span {
        font-size: 90px;
        color: $white;
        font-weight: bolder;
    }

    button {
        width: 200px;
        height: 60px;
        background: $gold;
        color: $blue;
        font-weight: 700;
        font-size: 23px;
        border-radius: 20px;
    }
}

.myTooltipClass {
    color: $gold;
    background: red;
}