@import "../global.scss";

.container {
    .heading {
        font-weight: 700;
        font-size: 25px;
        line-height: 35px;
        color: $blue !important;
        margin: 15px 0px !important;
        font-family: $fontfam2;

        @include scr-2k {
            font-size: 32px * 1.3;
        }

        @include scr-4k {
            font-size: 32px * 1.5;
        }

        @include phone {
            font-size: 21px;
            line-height: 27px;
            font-weight: 700;
        }

    }

    .FormContainer {
        overflow-y: scroll;
        height: 430px;

        &::-webkit-scrollbar {
            width: 4px;
            background: $gold;
            margin: 10px 10px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $gold;
            height: 20px;
            border-radius: 20px;
        }

        form {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            max-width: 550px;
            margin: auto;
            gap: 20px;

            @include scr-2k {
                max-width: 800px;
            }

            @include scr-4k {
                max-width: 1000px;
            }

            @include phone {}

            input {
                font-size: 16px;
            }

            .linkText {
                font-weight: 500;
                font-size: 18px;
                text-align: center;

                @include scr-2k {
                    font-size: 27px;
                    margin: 25px 0 0;
                }

                @include scr-4k {
                    font-size: 32px;
                    margin: 35px 0 0;
                }

                span {
                    color: $blue;
                    cursor: pointer;
                    font-weight: 600;
                    border-bottom: 1px solid $blue;
                }
            }
        }

        .levelSelectionSection {

            div {
                margin-top: 10px;
            }
        }

        .inputSection {
            label {
                display: flex;

                input {
                    height: 17px;
                    width: 17px;
                    margin: 0px 6px 0 0;
                }

                p {
                    .link {
                        color: $blue;
                        font-weight: 600;
                        border-bottom: 1px solid $blue;
                    }
                }
            }
        }

        .passwordStrengthSection {
            width: 265px;
            height: 10px;
            background-color: $silver;
            margin: 0 0 5px;
            border-radius: 10px;

            .passwordStrengthBar {
                transition: width 2s;
                height: 100%;
                border-radius: 10px;
            }

        }

        .passErrSection {
            width: 100%;

            span {
                color: $error;
                font-size: 16px;
            }
        }
    }

    .Para {
        color: $blue;
        // font-weight: 600;
    }
}

input[type="email"] {
    background: #F1F1F1 !important;
    border: #F1F1F1 !important;

    &:focus {
        border: 0px !important;
    }
}

.DiabledBtn {
    @include Btn;
    width: 130px;
    height: 40px;
}