@import '../global.scss';

.detailsContainer {
	max-width: 850px;
	height: 330px;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		display: none;
	}

	h1 {
		font-size: 25px;
		color: $blue;
		font-weight: 700;
	}

	.box {
		display: flex;
		flex-direction: row;
		width: 100%;
		flex-wrap: wrap;

		div {
			width: 50%;
			margin: 0 0 20px 0;
			padding-right: 15px;

			h4 {
				font-size: 18px;
				font-weight: 700;
				color: $blue;
				line-height: 22px;
				margin: 7px 0px;
			}

			p {
				font-size: 16px;
				word-break: break-all;
			}
		}
	}
}
