@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700;900');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat');
@import url('https://fonts.googleapis.com/css2?family=Rubik');
@import './global.scss';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: 'Rubik', 'Roboto', sans-serif;
	width: 100%;
	height: 100vh;
	overflow-x: hidden;
}

.App {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

button {
	cursor: pointer;
}

link {
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}
}

a {
	color: white;
	text-decoration: none;
}

input#clean {
	border: none;
	background-color: transparent;

	@include scr-2k {
		font-size: 23px;
	}

	@include scr-2k {
		font-size: 25px;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&[type='number'] {
		--moz-appearance: textfield;
	}

	&[type='checkbox'] {
		cursor: pointer;
	}

	&:focus {
		outline: none;
	}

	&[type='file'] {
		cursor: pointer;
		width: 100%;
	}
}

#flex {
	display: flex;
}
