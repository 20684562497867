@import "../global.scss";

.phoneContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background: #F1F1F1;
    border: 1px solid #F1F1F1;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    min-height: 47px;

    @include scr-2k {
        padding: 20px 10px;
    }

    @include scr-4k {
        padding: 20px 10px;
    }
}